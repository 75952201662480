import React from 'react'
import { graphql } from 'gatsby'

import Root from '../../components/Root'
import Meta from '../../components/Meta'
import { VideoHero } from '../../components/Heroes'

export const query = graphql`
    query getErrorDeDe {
        globalConfig: datoCmsGlobalConfig {
            locale
            errorHeading
            errorDescription
            errorBackgroundImage {
                fluid(imgixParams: { w: "2700", h: "1140", fit: "crop" }) {
                    aspectRatio
                    base64
                    height
                    sizes
                    src
                    srcSet
                    width
                }
            }
            errorVideo {
                title
                url
                width
                height
                provider
                providerUid
                thumbnailUrl
            }
            ratingIcon {
                url
                format
                gatsbyImageData
            }
            logos {
                url
                format
                gatsbyImageData
            }
            errorHeading
            errorBackgroundImage {
                fluid(imgixParams: { w: "2700", h: "1140", fit: "crop" }) {
                    aspectRatio
                    base64
                    height
                    sizes
                    src
                    srcSet
                    width
                }
            }
            errorVideo {
                title
                url
                width
                height
                provider
                providerUid
                thumbnailUrl
            }
            errorDescription
            emailLabel
            platformLabel
            pleaseSelectLabel
            channelNameLabel
            channelUrlLabel
            streamingPlatformLabel
            subscribersLabel
            referrerLabel
            genreLabel
            frequencyLabel
            stayUpdated
            followUs
            allRightsReserved
            copyright
            privacyPolicy
            cookieSettings
        }
    }
`

const Error = ({ data, ...props }) => {
    const globalConfig = data?.globalConfig?.edges?.[0]?.node
    const locale = 'de-de'

    return (
        <Root {...{ globalConfig, locale, ...props }}>
            <Meta />
            <VideoHero
                heading={globalConfig?.errorHeading}
                description={globalConfig?.errorDescription}
                backgroundImage={globalConfig?.errorBackgroundImage}
                video={globalConfig?.errorVideo}
            />
        </Root>
    )
}

export default Error
